<template>
    <div class="dropdown">
        <div tabindex="0" role="button">
            <component :is="activeLocaleFlag" class="h-4" :aria-label="$t(`locale.${activeLocale}`)" />
        </div>
        <ul tabindex="0" class="menu dropdown-content z-50 p-2 shadow bg-base-100 rounded-box w-52 mt-4">
            <li v-for="availableLocale in availableLocales" :key="availableLocale">
                <div v-if="activeLocale === availableLocale" class="!cursor-default">
                    <component
                        :is="getLocaleFlag(availableLocale)"
                        class="h-4 mr-2"
                        :aria-label="$t(`locale.${availableLocale}`)" />
                    {{ $t(`locale.${availableLocale}`) }}
                </div>
                <a
                    v-else
                    :href="route(routeName, {locale: availableLocale})"
                    :aria-label="$t(`locale.${availableLocale}`)">
                    <component
                        :is="getLocaleFlag(availableLocale)"
                        class="h-4 mr-2"
                        :aria-label="$t(`locale.${availableLocale}`)" />
                    {{ $t(`locale.${availableLocale}`) }}</a
                >
            </li>
        </ul>
    </div>
</template>

<script setup>
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue3';
import EnglishFlag from '@/images/components/flag_en.svg';
import ItalianFlag from '@/images/components/flag_it.svg';

const props = defineProps({
    currentLocale: {
        type: String,
        default: null,
    },
    routeName: {
        type: String,
        default: 'locale.update',
    },
});

const activeLocale = props.currentLocale ?? usePage().props?.locale ?? 'en';
const activeLocaleFlag = computed(() => (activeLocale === 'it' ? ItalianFlag : EnglishFlag));
const availableLocales = ['it', 'en'];

const getLocaleFlag = (locale) => {
    switch (locale) {
        case 'it':
            return ItalianFlag;
        case 'en':
        default:
            return EnglishFlag;
    }
};
</script>
